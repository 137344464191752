<template>
  <div class="wrapper">
    <BlogPost 
      v-for="post in posts" 
      :key="post.id" 
      :tags="post.tags"
      :title="post.title"
      :feature-img="post.feature_image"
      :date="post.created_at"
      :link="post.url"
      :excerpt="post.excerpt"
      :author="post.primary_author"
    />
    <div class="right-padding"></div>
  </div>
</template>

<script>
import BlogPost from "@/javascript/components/blog-posts/components/BlogPost.vue"

export default {
  name: 'BlogPosts',
  components: { BlogPost },
  props: ["limit", "tag"],  
  data () {
    return {
      posts: {}
    }
  },
  methods: {
    loadData () {
      this.$getBlogPosts(this.limit, this.tag)
        .then(response => {
          this.posts = response.posts;
        })
    }
  },
  mounted() {
    this.loadData();
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-wrap: wrap;
}
</style>
