import { createApp as createVue, h } from 'vue'
import BlogPosts from '@/javascript/components/blog-posts/views/BlogPosts.vue'
import { getBlogPosts } from '@/javascript/components/blog-posts/api'

export default function createApp (element) {
  const limit = element.getAttribute('data-limit');
  const tag = element.getAttribute('data-tag');

  const vm = createVue({
    render() { return h(BlogPosts, { limit, tag } )}
  })

  vm.config.globalProperties.$getBlogPosts = getBlogPosts
  vm.mount(element);

  return vm
}
