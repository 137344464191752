import axios from 'axios'

const BASE_URL = 'https://hubbado.ghost.io/ghost/api/v3/content'
const apiKey = '9663006612b5153244bc6358f6'
const apiInclude = '&include=authors,tags'
let apiFilter = '&filter=visibility:public'

export const getBlogPosts = async (limit, tag) => {
  apiFilter += `%2Btag:${tag}`;
  const apiLimit = `&limit=${limit}`
  return (await axios.get(`${BASE_URL}/posts?key=${apiKey}${apiInclude}${apiFilter}${apiLimit}`)).data
}
